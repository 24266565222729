import axios from 'axios';

export default axios.create({
    //Test
    // baseURL: 'https://testcopukservices.azurewebsites.net/';

    //Prod
    baseURL: 'https://copuk.azurewebsites.net/'
});

