
import React, { useContext, useState, useEffect} from 'react';
import {Route, Navigate } from 'react-router-dom'

import AuthContext from "./AuthProvider";


export default function PrivateRoute({ component: RouteComponent}) {
    const authUser = useContext(AuthContext);
    const isAuth = localStorage.getItem('isLoggedIn'); 
    

    if (authUser.auth.user) {
        return <RouteComponent />
    }
    return <Navigate to="/login" /> 
}

