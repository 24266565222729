import logo from "../../../assets/logo.png";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {BusyIndicator, Input, Bar, Button, Modals} from '@ui5/webcomponents-react';
import { useRef, useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faUnlockKeyhole
} from "@fortawesome/free-solid-svg-icons";
import apis from "../../../apis/api";
import {motion} from 'framer-motion';

const url = '/api/Accounts/ForgetPassword?email=';

function ForgotPassword() {
  const userRef = useRef();
  const errRef = useRef();
  const [user, setUser] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [enableBusyIndicator, setBusy] = useState();
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const showDialog = Modals.useShowDialog();
  const handleSubmit = () => {
    try{
    const response = apis.get(url+userRef.current.value);
    response.then(console.log(response));
    }
    catch(e){
      console.log(e);
    }
    const{close}=
    showDialog({
      headerText: 'Reset Password',
      children: "A reset password email has been sent to your registered email address...",
      footer: <Bar endContent={<Button onClick={() => close()}>Close</Button>} />
    });

  };
  const validateEmail = (e) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value))
    {
        e.target.valueState = "None";
    } else
    {
        e.target.valueState = "Error";
        e.target.valueStateMessage = "Please enter a valid email";
    }
  };

  const forgotPasswordStyle = {
    marginTop: "10px",
    fontFamily: "Poppins, sans-serif",
    textAlign: "center",
    color: "#02075d",
    textDecoration: "underline",
    cursor: "pointer",
  };

  const iconStyle = {
    fontSize: "18px",
    color: "#04a5e6",
    cursor: "pointer",
  };

  const buttonStyle = {
    color: "#ffffff",
    background: "linear-gradient(45deg, #02075d, #04a5e6)",
    padding: "10px 20px",
    borderRadius: "5px",
    border: "none",
    boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.2)",
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: "bold",
  };

  const greetingStyle = {
    textAlign: "center",
    color: "#ffffff",
    background: "linear-gradient(45deg, #02075d, #04a5e6)",
    padding: "10px",
    borderRadius: "8px",
    fontFamily: "Poppins, sans-serif",
    boxShadow: "0 3px 8px rgba(0, 0, 0, 0.2)",
    margin: "5px 0",
  };

  useEffect(() => {
    document.title = 'CMS - Recover Account';
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [user]);
  
  return (
    <motion.div initial={{opacity:0}} animate={{opacity:1}} exit={{opacity:0}} className="hold-transition  d-flex justify-content-center align-middle mt-5">
      <BusyIndicator active={enableBusyIndicator}>
      <div className="col-md-6">
      <div className="login-box">
        <div className="card card-outline card-primary">
          <div style={{
                  color: "#ffffff",
                  background: "linear-gradient(45deg, #02075d, #04a5e6)",
                }} className="card-header text-center">
            <a href="#" className="h1">
              <img src="https://copukcmsblobstorage.blob.core.windows.net/copcmsblobstore/cop logo white x2.png" style={{width: '40%'}}/>
            </a>
          </div>
          <div className="card-body">
          <div  style={greetingStyle}>
          Oops, looks like you've misplaced your password. No worries, we're here to help you 
          </div>
            <div>
              <div className="input-group mb-3" style={{paddingTop:'2%'}}>
              <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <input
                        type="text"
                        className="form-control"
                        id="username"
                        placeholder="Please enter your registered email address"
                        ref={userRef}
                        style={{
                          textAlign: "center",
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "18px",
                          flex: "1",
                          marginRight: "10px",
                        }}
                        onChange={validateEmail}
                        
                        required
                      />
                      <FontAwesomeIcon icon={faUser} style={iconStyle} />
                    </div>
              </div>
              <div className="mb-3 text-center">
                {/* <button style={{backgroundColor:"#040396"}}  className="btn btn-primary btn-block">
                  Reset Password
                </button> */}
                <button style={buttonStyle} onClick={handleSubmit}>
                  Reset Password
                      <FontAwesomeIcon
                        icon={faUnlockKeyhole}
                        style={{ marginLeft: "10px" }}
                      />
                </button>
              </div>
              <p className="mb-1">
              <Link style={forgotPasswordStyle} to="/login">Go back to login</Link>
            </p>
            </div>
          </div>
        </div>
      </div>
      </div>
      </BusyIndicator>
    </motion.div>
  );
}

export default ForgotPassword;
