import React, { useEffect } from 'react';
import { Page, IllustratedMessage } from '@ui5/webcomponents-react';

function ErrorPage(){
    useEffect(()=>{
        debugger
        window.document.title = "Unauthorized";
    },[]);
    return(
        <Page
        style={{
            height: '100vh'
        }}
        >
            <IllustratedMessage titleText="Unauthorized 401" stretch="true" subtitleText="You are not authorized to access this page"/>
        </Page>
    )
};
export default ErrorPage;