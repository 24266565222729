import React from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../../contexts/AuthProvider";
import { BusyIndicator } from "@ui5/webcomponents-react";
import { useRef, useState, useEffect, useContext } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faUser,
  faSignIn,
} from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";

const LOGIN_URL = "/oauth/token";

function Login() {
  const { setAuth } = useContext(AuthContext);
  const userRef = useRef();
  const errRef = useRef();
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [enableBusyIndicator, setBusy] = useState();
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    setAuth(localStorage);
    document.title = "CMS - Login";
    userRef.current.focus();
    localStorage.clear();
  }, []);
  useEffect(() => {
    setErrMsg("");
  }, [user, password]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setBusy(true);
    const details = {
      username: user,
      password: password,
      grant_type: "password",
      isLoggedIn: true,
    };

    var formBody = [];
    for (var property in details) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    // console.log(formBody);

    await axios
      .post("https://copuk.azurewebsites.net/oauth/token", formBody, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        const accessToken = response?.data.access_token;
        const expires_in = response?.data.expires_in;

        localStorage.setItem("ACCESS_TOKEN", accessToken);
        localStorage.setItem("EXPIRES_IN", expires_in);
        localStorage.setItem("USER", user);
        setAuth({ user, accessToken, expires_in });
        setUser("");
        setPassword("");
        setSuccess(true);
        setBusy(false);
        console.clear();
        navigate("/");
      })
      .catch((err) => {
        if (!err?.response) {
          setBusy(false);
          setErrMsg("No Server Response");
        } else if (err.response?.status === 400) {
          setBusy(false);
          setErrMsg("Missing Username or Password");
        } else if (err.response?.status === 401) {
          setBusy(false);
          setErrMsg("Unauthorized");
        } else {
          setBusy(false);
          setErrMsg("Login Failed");
        }
        errRef.current.focus();
      });
  };

  const greetingStyle = {
    textAlign: "center",
    color: "#ffffff",
    background: "linear-gradient(45deg, #02075d, #04a5e6)",
    padding: "10px",
    borderRadius: "8px",
    fontFamily: "Poppins, sans-serif",
    boxShadow: "0 3px 8px rgba(0, 0, 0, 0.2)",
    margin: "5px 0",
  };

  const buttonStyle = {
    color: "#ffffff",
    background: "linear-gradient(45deg, #02075d, #04a5e6)",
    padding: "10px 20px",
    borderRadius: "5px",
    border: "none",
    boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.2)",
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: "bold",
  };

  const iconStyle = {
    fontSize: "18px",
    color: "#04a5e6",
    cursor: "pointer",
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const ssoButtonStyle = {
    padding: "10px 20px",
    marginLeft: "1%",
    fontSize: "16px",
    cursor: "pointer",
    backgroundColor: "#4285F4",
    color: "#ffffff",
    border: "none",
    borderRadius: "5px",
    boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.2)",
    transition: "background-color 0.3s ease",
  };

  const forgotPasswordStyle = {
    marginTop: "10px",
    fontFamily: "Poppins, sans-serif",
    textAlign: "center",
    color: "#02075d",
    textDecoration: "underline",
    cursor: "pointer",
  };
  const handleForgotPasswordClick = () => {
    navigate("/forgotpassword");
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="hold-transition  d-flex justify-content-center align-middle mt-5"
    >
      <BusyIndicator active={enableBusyIndicator}>
        <div
          className="col-md-6"
        >
          <div className="login-box">
            <div className="card card-outline card-primary">
              <div
                style={{
                  color: "#ffffff",
                  background: "linear-gradient(45deg, #02075d, #04a5e6)",
                }}
                className="card-header text-center"
              >
                <a href="#" className="h1">
                  <img
                    src="https://copukcmsblobstorage.blob.core.windows.net/copcmsblobstore/cop logo white x2.png"
                    style={{ width: "40%" }}
                  />
                </a>
              </div>
              <div className="card-body">
                <p
                  ref={errRef}
                  className={errMsg ? "errmsg" : "offscreen"}
                  aria-live="assertive"
                >
                  {errMsg}
                </p>
                <div className="lead" style={greetingStyle}>
                  Welcome! Log in to manage your member profile.
                </div>
                <form onSubmit={handleSubmit} style={{ paddingTop: "3%" }}>
                  <div className="input-group mb-3">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <input
                        type="text"
                        className="form-control"
                        id="username"
                        placeholder="Enter your COP Email"
                        ref={userRef}
                        style={{
                          textAlign: "center",
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "18px",
                          flex: "1",
                          marginRight: "10px",
                        }}
                        onChange={(e) => setUser(e.target.value)}
                        value={user}
                        required
                      />
                      <FontAwesomeIcon icon={faUser} style={iconStyle} />
                    </div>
                  </div>
                  <div className="input-group mb-3">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        id="password"
                        placeholder="Enter your password"
                        style={{
                          textAlign: "center",
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "18px",
                          flex: "1",
                          marginRight: "10px",
                        }}
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                        required
                      />
                      <FontAwesomeIcon
                        icon={showPassword ? faEyeSlash : faEye}
                        style={iconStyle}
                        onClick={handleTogglePassword}
                      />
                    </div>
                  </div>
                  <div className="mb-3 text-center">
                    <button style={buttonStyle}>
                      Sign In
                      <FontAwesomeIcon
                        icon={faSignIn}
                        style={{ marginLeft: "5px" }}
                      />
                    </button>
                    {/* <button  style={ssoButtonStyle}>
                    <FontAwesomeIcon icon={faLock} style={{ marginRight: '5px' }} />
                      Login with SSO
                    </button> */}
                  </div>
                </form>
                <p
                  className="mb-1"
                  style={forgotPasswordStyle}
                  onClick={handleForgotPasswordClick}
                >
                  Forgot Password?
                </p>
              </div>
            </div>
          </div>
        </div>
      </BusyIndicator>
    </motion.div>
  );
}

export default Login;
