import React, { useContext, useEffect, useState, useRef } from 'react'
import { Avatar, FlexBox, Form, FormItem, FileUploader, Button } from '@ui5/webcomponents-react';
import apis from '../../apis/api';
function UploadFile(props) {
    const dialogClose = () => {
        props.dialogClose();
    };
    const[ imageUrl, setUrl] = useState("");
    useEffect(()=>{
        var url = "https://copcmsimages.blob.core.windows.net/imagestore/"+props.memberId+".jpg?t="+new Date().getTime();
        setUrl(url);
    }, [props]);
    const onImageUpload = (e) => {
        var type=(e.currentTarget.files[0].type === "image/jpeg")?".jpg":(e.currentTarget.files[0].type==="image/png")?".png":"";

        var uploadUrl = "api/dataManager/uploadFile?type="+type+"&memberId="+props.memberId;
        const formData = new FormData();
        formData.append("Files",e.currentTarget.files[0]);
        apis.post(uploadUrl, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        }).then(response=>{
            console.log(response);
            setUrl("https://copcmsimages.blob.core.windows.net/imagestore/"+props.memberId+".jpg?t="+new Date().getTime());
        }).catch(err=>{
            console.log(err);
        });
    };

    return(<> 
            <Form  columnsL={1}
            columnsXL={1}
            style={{
              alignItems: 'baseline'
            }}>
                <FormItem label="" style={{alignItems:"Center"}}>
                
                 <img style={{height:"45%", width:"45%"}} src={imageUrl} alt="No profile picture found"/>
               
                </FormItem>
                <FormItem label="">
                    <></>
                </FormItem>
                <FormItem label="">
                <FileUploader multiple="false" style={{width:"100"}} onChange={onImageUpload}>
                    <Button style={{width:"14.5vw"}}>
                        Upload From Computer
                    </Button>
                </FileUploader>
                </FormItem>
                <FormItem label="">
                <FlexBox direction='Row' style={{width:"50%"}}>
                        <Button design="Emphasized" style={{width:"50%"}}>
                           Save Profile Picture
                        </Button>
                        <Button onClick={props.dialogClose} style={{width:"50%"}}>
                            Cancel
                        </Button>
                        </FlexBox>
                </FormItem>
            </Form>
           
    </>);
}
export default UploadFile;