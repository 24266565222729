import React, { useContext, useEffect, useState, useRef } from 'react'
import AuthContext from "../../contexts/AuthProvider";
import {motion} from 'framer-motion';
import logo from '../../assets/badge.png';
import { useNavigate, useParams } from 'react-router-dom';
import EditProfile from '../MemberProfile/EditProfile1';
import { Label, Token, ProgressIndicator, ObjectPageSection, FormGroup, Form, Toast, FormItem, Text, DynamicPageHeader, DynamicPageTitle, ObjectPage, Page,Bar , ObjectStatus, Avatar, Link, Button,FlexBox } from '@ui5/webcomponents-react';
import apis from '../../apis/api';
import {
  faBackward
} from "@fortawesome/free-solid-svg-icons";
import ShellBarComponent from '../../components/ShellBarComponent';
function MemberDetail() {
    const authUser = useContext(AuthContext);
    const navigate = useNavigate(); 
    const toastRef = useRef();
    const [display, setDisplayMode] = useState(true);
    let { memberId } = useParams();
    const [editContent, setEditContent] = useState();
    const [memberData, setMemberData] = useState({});
    const [memberAddress, setMemberAddress] = useState({});
    const [memberProfCat, setProfCat] = useState({});
    const [memberAssemblyInfo, setAssemblyInfo] = useState({});
    const [memberAssemblyAddress, setMemberAssemblyAddress] = useState({});
    const [memberDistrict, setMemberDist] = useState({});
    const [memberSalStatus, setSalStatus] = useState({});
    const [memberHomeCell, setHomeCell] = useState({});
    const [memberDisType, setDisabilityType] = useState([]);
    const onProfileEdit = (e) => {
      setDisplayMode(false);
      setEditContent(<EditProfile onEditProfileCancel={onEditProfileCancel} memberData = {memberData} memberDisType={memberDisType} memberAddress={memberAddress}
        memberProfCat={memberProfCat} memberDistrict={memberDistrict} memberAssemblyInfo={memberAssemblyInfo} memberAssemblyAddress={memberAssemblyAddress} memberSalStatus={memberSalStatus} memberHomeCell={memberHomeCell}/>);
    };
    const onEditProfileCancel = () => {
      setDisplayMode(true);
      getMemberDetails();
      setEditContent(null);
    };
    const showToast = () => {
      toastRef.current.show();
    };
    const [message, setMessage] = useState("");
    // const deleteMember = async (e) => {
    //   const response = await apis.delete("api/Members?id="+e.currentTarget.id,{
    //     headers: {
    //       "Content-Type": "application/json",
    //       "Authorization": "Bearer " + localStorage.getItem('ACCESS_TOKEN')
    //     }
    //   }).then(res=>{
    //     setMessage("Member Deleted.");
    //     showToast();
    //     navigate('/memberManager');
    //   }).catch(err=>{
    //     setMessage("Member could not be deleted.");
    //     showToast();
    //   });
    // };
    const getMemberDetails = async () => {
      const response = await apis.get("api/MemebershipManager/getMyDetails",{
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + localStorage.getItem('ACCESS_TOKEN')
      }
    }).then(data=>{
        setMemberData(data.data);
        setMemberAddress(data.data.memberAddress);
        setProfCat(data.data.profCat);
        setAssemblyInfo(data.data.localAssembly);
        setMemberAssemblyAddress(data.data.localAssembly.assemblyAddress);
        setMemberDist(data.data.localAssembly.district);
        setSalStatus(data.data.salvationStatus);
        setHomeCell(data.data.homeCell);
        setDisabilityType(data.data.disabilityType);
      })
      .catch(err=>{console.log(err)});
    };
    const imageNotLoaded = function(oControl){
      oControl.currentTarget.src="https://copukcmsblobstorage.blob.core.windows.net/copcmsblobstore/user.png";
    };
    useEffect(() => {
      document.title = 'CMS - My Profile';
      getMemberDetails();
    },[]);

    const goBack = ()=>{
      navigate('/');
    };
    return (
        <motion.div initial={{ opacity: 0 }} animate={{ width: "100%", opacity: 1 }} exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}>
          <ShellBarComponent />
          <Toast ref={toastRef}>{message}</Toast>
          <Page header={<Bar><Label>Member Profile</Label></Bar>} style={{height: '100vh'}}>
          {editContent}
          {display&&
          <ObjectPage
            // footer={<Bar design="FloatingFooter" endContent={<><Button design="Positive">Accept</Button><Button design="Negative">Reject</Button></>} />}
            headerContent={<DynamicPageHeader><FlexBox alignItems="Stretch"><FlexBox alignItems="Start" direction="Column"><Link href={"tel:"+memberData.mobileNumber}>{memberData.mobileNumber}</Link><Link href={"mailto:"+memberData.emailAddress}>{memberData.emailAddress}</Link>
            {memberData.disabled &&<ObjectStatus showDefaultIcon state="Warning" children="Disabled" />}{((memberDisType)? memberDisType.mobilitySupport:false)&& <ObjectStatus showDefaultIcon state="Warning" children="Mobility Support Required"/>}
            </FlexBox><FlexBox alignItems="Start" direction="Column" style={{ paddingTop:"0.5em", width:"10em"}}></FlexBox></FlexBox></DynamicPageHeader>}
            headerContentPinnable
            headerTitle={<DynamicPageTitle actions={<><Button design="Emphasized" id={memberData.memberId} onClick={onProfileEdit}>Edit</Button></>} header={memberData.title+" "+memberData.firstName+((memberData.middleName!=="")?" "+memberData.middleName:" "+memberData.lastName)+((memberData.middleName!=="")?" "+memberData.lastName:"")}><ObjectStatus state="Success">Member</ObjectStatus> </DynamicPageTitle>}
            image={<Avatar
              colorScheme="Accent6"
              icon="employee"
              shape="Circle"
              // style={{paddingTop:"5px"}}
              size="XL"
            >
              <img src={"https://copukcmsblobstorage.blob.core.windows.net/copcmsblobstore/"+memberData.memberId+".jpg?t="+new Date().getTime()} alt="Image" onError={imageNotLoaded}/>
            </Avatar>}
            imageShapeCircle
            onSelectedSectionChange={function noRefCheck() { }}
            onToggleHeaderContent={function noRefCheck() { }}
            showHideHeaderButton
            style={{
              height: '700px'
            }}
          >
            <ObjectPageSection
              aria-label="Personal"
              id="contact-info"
              titleText="Contact Information"
            >
              <ProgressIndicator displayValue="Profile Completion Status 70%" value="70" valueState="Success"/>
              {/* <ObjectPageSubSection
                actions={<><Button design="Emphasized" style={{ minWidth: '120px' }}>Custom Action</Button><Button design="Transparent" icon="action-settings" tooltip="settings" /><Button design="Transparent" icon="download" tooltip="download" /></>}
                aria-label="Contact Information"
                id="personal-connect"
                titleText="Contact Information"
              > */}
                <Form
                  columnsL={2}
                  columnsXL={2}
                  style={{
                    alignItems: 'baseline'
                  }}
                >
                  <FormGroup titleText="Phone Numbers">
                    <FormItem label="Mobile Number">
                      <Link href={"tel:"+memberData.mobileNumber}>
                        {(memberData.mobileNumber)?memberData.mobileNumber:"Not Updated"}
                      </Link>
                    </FormItem>
                    <FormItem label="Telephone Number">
                      <Link href={"tel:"+memberData.mobileNumber}>
                        {(memberData.telNumber)?memberData.telNumber:"Not Updated"}
                      </Link>
                    </FormItem>
                    <FormItem label="Emergency Contact">
                      <Link href={"tel:"+memberData.emergencyContact}>
                        {(memberData.emergencyContact)?memberData.emergencyContact:"Not Updated"}
                      </Link>
                    </FormItem>
                    <FormItem label="Email Address">
                      <Link href={"mailto:"+memberData.emailAddress}>
                        {memberData.emailAddress}
                      </Link>
                    </FormItem>
                  </FormGroup>
                  {/* <FormGroup titleText="Social Accounts">
                    <FormItem label="LinkedIn">
                      <Link href="https://www.linkedin.com/in/cyril-johnson-cj/" target="_blank">
                        cyril-johnson-cj
                      </Link>
                    </FormItem>
                    <FormItem label="Twitter">
                      <Link href="https://twitter.com/CyrilJohnson2" target="_blank">
                        CyrilJohnson2
                      </Link>
                    </FormItem>
                    <FormItem label="Instagram">
                      <Link href="https://www.instagram.com/cyriljohnsoncj/" target="_blank">
                        cyriljohnsoncj
                      </Link>
                    </FormItem>
                    <FormItem label="Facebook">
                      <Link href="https://www.facebook.com/people/Cyril-Johnson/100082081875220/" target="_blank">
                       Cyril-Johnson/100082081875220/
                      </Link>
                    </FormItem>
                  </FormGroup> */}
                  <FormGroup titleText="Addresses">
                    <FormItem label="Home Address">
                      <Text>
                        {(memberAddress)?memberAddress.addressLine1:"Not Updated"}
                      </Text>
                    </FormItem>
                    <FormItem label="">
                      <Text>
                       {(memberAddress)?memberAddress.addressLine2:"Not Updated"}
                      </Text>
                    </FormItem>
                    <FormItem label="">
                      <Text>
                      {(memberAddress)?memberAddress.locality:"Not Updated"}
                      </Text>
                    </FormItem>
                    <FormItem label="">
                      <Text>
                      {(memberAddress)?memberAddress.city:"Not Updated"}
                      </Text>
                    </FormItem>
                    <FormItem label="">
                      <Text>
                      {(memberAddress)?memberAddress.region:"Not Updated"}
                      </Text>
                    </FormItem>
                    <FormItem label="">
                      <Text>
                      {(memberAddress)?memberAddress.country + "-"+ memberAddress.postalCode:"Not Updated"}
                      </Text>
                    </FormItem>
                  </FormGroup>
                </Form>
                </ObjectPageSection>
              <ObjectPageSection
              titleTextUppercase="false"
              aria-label="Personal Information"
              id="personal-info"
              titleText="Personal Information">
                <Form
                  columnsL={2}
                  columnsXL={2}
                  style={{
                    alignItems: 'baseline'
                  }}
                >
                  <FormGroup titleText="">
                  <FormItem label="Age">
                      <Text>
                        {(memberData.dob)?(new Date().getFullYear() - new Date(memberData.dob).getFullYear()):"Not Updated"}
                      </Text>
                    </FormItem>
                    <FormItem label="Date Of Bith">
                      <Text>
                        {memberData.dob}
                      </Text>
                    </FormItem>
                    <FormItem label="Gender">
                      <Text>
                        {(memberData.gender==="M")?"Male":"Female"}
                      </Text>
                    </FormItem>
                    <FormItem label="Home Town">
                      <Text>
                        {memberData.homeTown}
                      </Text>
                    </FormItem>
                    <FormItem label="Nationality">
                      <Text>
                        {memberData.nationality}
                      </Text>
                    </FormItem>
                    <FormItem label="Country Of Birth">
                      <Text>
                        {memberData.countryOfBirth}
                      </Text>
                    </FormItem>
                    <FormItem label="Country Of Residence">
                      <Text>
                        {memberData.countryOfResidence}
                      </Text>
                    </FormItem>
                  </FormGroup>
                  <FormGroup titleText="">
                    <FormItem label="Marrital Status">
                      <Text>
                        {memberData.maritalStatus}
                      </Text>
                    </FormItem>
                    <FormItem label="Profession">
                      <Text>
                        {memberData.profession}
                      </Text>
                    </FormItem>
                    <FormItem label="Professional Categorty">
                      <Text>
                        {(memberProfCat)?(memberProfCat.profCatName+" ("+memberProfCat.profCatDesc+")"):"Not Updated"}
                      </Text>
                    </FormItem>
                    <FormItem label="Date Of Joining">
                      <Text>
                        {memberData.dateOfJoining}
                      </Text>
                    </FormItem>
                    <FormItem label="Date Of Dedication">
                      <Text>
                        {memberData.dedicationDate}
                      </Text>
                    </FormItem>
                    {memberData.disabled &&<>
                    <FormItem label="Disabled">
                      <Text>
                        Yes
                      </Text>
                    </FormItem>
                    <FormItem label="Disability Name">
                    <Text>
                        {memberDisType.disabilityName}
                    </Text>
                  </FormItem>
                  <FormItem label="Medical Condition">
                  <Text>
                    {memberDisType.medCondition}
                  </Text>
                </FormItem>
                <FormItem label="Mobility Support">
                  <Text>
                    {(memberDisType.mobilitySupport)?"Required":"Not Required"}
                  </Text>
                </FormItem>
                </>
                    }
                  </FormGroup>
                </Form>
              {/* </ObjectPageSubSection> */}
            </ObjectPageSection>
            <ObjectPageSection
            titleTextUppercase="false"
              aria-label="Assembly Infomration"
              id="assembly-info"
              titleText="Assembly Infomration">
                  <Form
                  columnsL={2}
                  columnsXL={2}
                  style={{
                    alignItems: 'baseline'
                  }}
                >
                  <FormGroup titleText="Local Assembly">
                    <FormItem label="Assembly Name">
                        <Text>
                          {memberAssemblyInfo.assemblyName}
                        </Text>
                    </FormItem>
                    <FormItem label="Assembly Language">
                        <Text>
                          {memberAssemblyInfo.langSpoken}
                        </Text>
                    </FormItem>
                    <FormItem label="Assembly District">
                        <Text>
                          {memberDistrict.districtName}
                        </Text>
                    </FormItem>
                    <FormItem label="Assembly Address">
                      <Text>
                        {memberAssemblyAddress.addressLine1}
                      </Text>
                    </FormItem>
                    <FormItem label="">
                      <Text>
                       {memberAssemblyAddress.addressLine2}
                      </Text>
                    </FormItem>
                    <FormItem label="">
                      <Text>
                      {memberAssemblyAddress.locality}
                      </Text>
                    </FormItem>
                    <FormItem label="">
                      <Text>
                      {memberAssemblyAddress.city}
                      </Text>
                    </FormItem>
                    <FormItem label="">
                      <Text>
                      {memberAssemblyAddress.region}
                      </Text>
                    </FormItem>
                    <FormItem label="">
                      <Text>
                      {memberAssemblyAddress.country + "-"+ memberAssemblyAddress.postalCode}
                      </Text>
                    </FormItem>
                  </FormGroup>
                  <FormGroup titleText="Salvation Status & Home Cell">
                    <FormItem label="Have received salvation?">
                      <Text>
                      {(memberSalStatus)?memberSalStatus.salvationStatusText:"Not Updated"}
                      </Text>
                    </FormItem>
                    <FormItem label="Salvation Date">
                      <Text>
                      {(memberSalStatus)?memberSalStatus.date:"Not Updated"}
                      </Text>
                    </FormItem>
                    <FormItem label="Communicant">
                      <Text>
                      {(memberSalStatus)?(memberSalStatus.communicantBool)?"Yes":"No":"Not Updated"}
                      </Text>
                    </FormItem>
                    <FormItem label="Home Cell Name">
                      <Text>
                      {(memberHomeCell)?(memberHomeCell.homeCellName):"Not Updated"}
                      </Text>
                    </FormItem>
                  </FormGroup>
                </Form>
              </ObjectPageSection>
              <ObjectPageSection
                  aria-label="Education Details"
                  id="edu-profile"
                  titleText="Education Details"
                  >
                    <Label>No details added. Please click on edit and add educational details</Label>
              </ObjectPageSection>
              <ObjectPageSection
                  aria-label="Career Profile"
                  id="career-profile"
                  titleText="Career Profile"
                  >
                   <Label>No details added. Please click on edit and add career profile</Label>
              </ObjectPageSection>
              <ObjectPageSection
                titleTextUppercase="false"
                  aria-label="Traditional and Royal Details"
                  id="trad-royal-dets"
                  titleText="Traditional and Royal Details">
                    <Label>No details added. Please click on edit and add traditional and royal details</Label>
              </ObjectPageSection>
          </ObjectPage>}
          {/* ---------------------------------------------------------------------------------------- */}
          </Page>
        </motion.div>
     
    )
}

export default MemberDetail
