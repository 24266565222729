import React from 'react'
import ProfileLayout from './ProfileLayout';
function Profile() {
    return (
        <div>
            <ProfileLayout />
        </div>
    )
}
export default Profile;
