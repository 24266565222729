import React from "react";
import Login from "../pages/auth/Login/Login";
import ForgotPassword from "../pages/auth/ForgetPassword/ForgotPassword"
import ErrorPage from "../pages/ErrorPage/ErrorPage";
import { Routes, Route, useLocation } from 'react-router-dom';
import Profile from "../pages/MemberProfile/Profile";
import PrivateRoute from "../contexts/PrivateRoute";
import {AnimatePresence} from 'framer-motion';
export function AnimatedRoutes(){
    const location = useLocation();
    return(
        <AnimatePresence>
            <Routes location={location} key={location.pathname}>
                {/* <Route  path='/' element={<PrivateRoute component={Home}/>}/> */}
                <Route  path='/' element={<PrivateRoute component={Profile}/>}/>
                <Route  path='/login'  element={<Login />}/>
                <Route  path='*'  element={<ErrorPage />}/>
                <Route  path='/forgotPassword'  element={<ForgotPassword />}/>
        </Routes>
    </AnimatePresence>
  );
}
