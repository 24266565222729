import logo from './logo.svg';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import AuthContext from "./contexts/AuthProvider";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import {AnimatedRoutes} from './utility/AnimatedRoutes';
import React, { useContext }  from 'react';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
          <AnimatedRoutes />
      </BrowserRouter>
    </div>
  );
}

export default App;
